






































import {defineComponent} from '@vue/composition-api';
import DynamicQuestionMixin from '@/tasks/mixins/dynamic-question';
import StembleLatex from '@/tasks/components/StembleLatex.vue';
import STextarea from '@/common/components/STextarea.vue';

export default defineComponent({
  name: 'UCIrvineChem1LDELN3OISafety1',
  components: {STextarea, StembleLatex},
  mixins: [DynamicQuestionMixin()],
  data() {
    return {
      inputs: {
        listedChemicals: null,
        safety2Answer: null,
      },
      attachments: [] as File[],
      questions: [
        {
          text: 'Safety Moment:',
          inputName: 'safety2Answer',
        },
      ],
    };
  },
});
